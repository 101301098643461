'use client'

import { useState, useEffect } from 'react'
import { RootContext } from '@/modules/contextService'
import { IUser } from '@/interfaces/IUser'
import tokenService from '@/modules/tokenService'
import { PATHWAY } from '@/modules/httpService'
import cookieService from '@/modules/cookieService'
import { usePathname } from 'next/navigation'

export interface RootProviderProps {
  children: React.ReactNode
}

export interface IRootData {
  user?: IUser | null
  loading?: boolean
  theme?: string
}

function RootProvider ({ children }: RootProviderProps) {
  const [rootData, setRootData] = useState<IRootData>({ user: undefined, loading: true, theme: '' })
  // const { user } = rootData
  const [loading, setLoading] = useState(false)
  const pathname = usePathname()

  async function getUserData () {
    setLoading(true)

    const res = await tokenService.setUser(
      rootData,
      setRootData
    )
    setRootData({ ...rootData, user: res.user, loading: false })

    setLoading(false)
  }

  function loadThemeFromCookie () {
    const storedTheme = cookieService.getCookie('theme')

    if (!storedTheme) {
      cookieService.setCookie('theme', storedTheme || 'dark')
    }
    if (pathname !== '/') {
      document.documentElement.setAttribute('data-theme', storedTheme || 'dark')
      setRootData({
        ...rootData,
        theme: storedTheme || 'dark'
      })
    } else {
      document.documentElement.setAttribute('data-theme', 'dark')
      setRootData({
        ...rootData,
        theme: 'dark'
      })
    }
  }

  async function checkReferral () {
    const referrer = document.referrer

    // determine if the pathname is the same as the referrer
    const matches = window.location.pathname === referrer

    if (referrer && !matches) {
      try {
        console.log('posting referrer')
        const slug = window.location.pathname
        await PATHWAY.post('/referrers', {
          referrer,
          slug
        })
      } catch (err) {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    async function loadUser () {
      if (!loading) {
        await Promise.allSettled([
          getUserData(),
          checkReferral()
        ])
      }
    }
    loadThemeFromCookie()
    loadUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RootContext.Provider value={{ rootData, setRootData }}>
      {children}
    </RootContext.Provider>
  )
}

export default RootProvider
